import React from "react";
import { MenuItem } from "../../menu-item";
import Button from "../../../atoms/Button";
import PT from "prop-types";
import { SvgIconHandler } from "../../../atoms/svg-icon-hadler";
import "./side-menu.m.css";

export default function SideMenuItem({ index, item, toggleHandler, showMenuSection, showSectionHandler, textColor="#0C1215" }) {
  return (
    <li>
      <div styleName="menu-item-div">
        <MenuItem
          changeTextWeight={item?.data?.color.toLowerCase() !== "#ffffff"}
          menuStyle="side-menu-link"
          item={item}
          toggleHandler={toggleHandler}
          fontSize="16px"
          textColor={textColor}
        />
        {item?.children?.length > 0 && (
          <Button styleName="down-arrow" type="submit" onClick={() => showSectionHandler(index)}>
            <SvgIconHandler
              styleName={showMenuSection === index ? "down-arrow-rotate" : ""}
              type="arrow"
              width="28px"
              height="28px"
            />
          </Button>
        )}
      </div>
    </li>
  );
}

SideMenuItem.propTypes = {
  index: PT.number,
  item: PT.object,
  toggleHandler: PT.func,
  showMenuSection: PT.string,
  showSectionHandler: PT.func,
};
