import { renderBreakingNews, renderComponent, renderIsomorphicComponent } from "@quintype/framework/client/start";
import get from "lodash/get";

import LoadingIndicatorComponent from "../isomorphic/components/atoms/loading-indicator";
import { BreakingNewsView } from "../isomorphic/components/breaking-news-view";
import { Footer } from "../isomorphic/components/layouts/footer";
import Header from "../isomorphic/components/layouts/header";
import MobileNavMenus from "../isomorphic/components/layouts/header/mobile-nav-menu";
import HomePageMast from "../isomorphic/components/layouts/home-page-mast";
import MenuGroup from "../isomorphic/components/layouts/menu-group";
import SectionMast from "../isomorphic/components/pages/section-mast";
import { loadChartbeat } from "../isomorphic/components/utils/chartbeat";
import { pickComponent } from "../isomorphic/pick-component";

export function preRenderApplication(store) {
  const disableServiceWorkerCache = get(
    store.getState(),
    ["qt", "config", "publisher-attributes", "disableServiceWorkerCache"],
    false
  );
  const hydrate = { hydrate: disableServiceWorkerCache || !global.qtLoadedFromShell };
  const breakingNewsConfig = get(store.getState(), ["qt", "config", "publisher-attributes", "breaking_news"], {});
  const pageType = get(store.getState(), ["qt", "pageType"], null);
  const breakingNewsInterval =
    breakingNewsConfig.interval && breakingNewsConfig.interval <= 60 ? 60 : breakingNewsConfig.interval;
  const breakingNewsbaseProps = {
    hydrate,
    updateInterval: breakingNewsInterval * 1000,
  };
  if (pageType !== "user-login") {
    renderComponent(Footer, "footer", store);
    renderComponent(MenuGroup, "menu-group", store, hydrate);
    renderComponent(Header, "nav-bar", store, hydrate);
    renderComponent(MobileNavMenus, "mobile-nav-menus", store, hydrate);
    pageType === "home-page" && renderComponent(HomePageMast, "home-page-mast", store, hydrate);
    pageType !== "home-page" &&
      pageType !== "author-page" &&
      pageType !== "tag-page" &&
      renderComponent(SectionMast, "section-mast", store, hydrate);
    breakingNewsConfig.is_enable &&
      pageType !== "profile-page" &&
      renderBreakingNews("breaking-news-container", store, BreakingNewsView, breakingNewsbaseProps);
  }
  renderComponent(LoadingIndicatorComponent, "loading-indicator", store);
}

// This is a separate file as everything from here on is hot reloaded when the app changes
export function renderApplication(store) {
  const enableAds = get(store.getState(), ["qt", "config", "ads-config", "dfp_ads", "enable_ads"]);
  const pageType = get(store.getState(), ["qt", "pageType"], null);
  const disableServiceWorkerCache = get(
    store.getState(),
    ["qt", "config", "publisher-attributes", "disableServiceWorkerCache"],
    false
  );
  loadChartbeat();

  // Hydrate if SW cache is disabled
  if (disableServiceWorkerCache) {
    return renderIsomorphicComponent("container", store, pickComponent, { hydrate: true });
  }
  renderIsomorphicComponent("container", store, pickComponent, {
    hydrate: !global.qtLoadedFromShell,
  });
}
