import get from "lodash/get";
import { getCollectionitems, getSearchPageItems, getStories, getAuthorStories } from "../../../api/utils";

export const isValidEmail = (email) => {
  const re =
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
  if (!re.test(email)) return false;
  if (email.length < 6 || email.length > 150) return false;
  return true;
};

export const getLoadMoreStories = async ({
  offset,
  limit,
  isSearchPage = false,
  slug,
  query,
  shouldUseCollection,
  setStories,
  storiesToRender,
  setStoriesToRender,
  stories,
  isSectionPage,
  authorId,
}) => {
  if (isSearchPage) {
    const loadMoreStories = await getSearchPageItems(slug, offset, limit);
    setStories(stories.slice(0, storiesToRender).concat(loadMoreStories));
    setStoriesToRender(storiesToRender + limit);
    return null;
  }

  if (shouldUseCollection && isSectionPage) {
    const loadMoreStories = await getCollectionitems(slug, offset, limit);
    setStories(
      stories
        .slice(0, storiesToRender)
        .concat(loadMoreStories)
        .map((item) => item.story)
    );
    setStoriesToRender(storiesToRender + limit);
    return null;
  }

  if (authorId) {
    const loadMoreStories = await getAuthorStories(authorId, offset, limit);
    setStories(stories.slice(0, storiesToRender).concat(loadMoreStories));
    setStoriesToRender(storiesToRender + limit);
    return null;
  }

  const loadMoreStories = await getStories(query, slug, offset, limit);
  setStories(stories.slice(0, storiesToRender).concat(loadMoreStories));
  setStoriesToRender(storiesToRender + limit);
  return null;
};

export function removeHtmlTags(str) {
  if (str === null || str === "") return false;
  else str = str.toString();

  return str.replace(/(<([^>]+)>)/gi, "");
}

export const getMagazineSlug = (item, magazineSlug) => {
  return `magazine/${magazineSlug}/issues/${item.slug}`;
};

export const getSlug = (collection, config = {}) => {
  const { magazineSlug = "", isArchivePage = false } = config;
  if (isArchivePage) {
    return `/magazine/archive/${magazineSlug}`;
  }
  if (magazineSlug) {
    return `/magazine/${magazineSlug}/${collection.slug}`;
  }
  const isSection = collection.template === "section";
  if (isSection) {
    const sectionId = get(collection, ["metadata", "section", "0", "id"], "");
    const { sections } = config;
    const section = sections && sections.find((section) => section.id === sectionId);
    return section ? section?.["section-url"] : "/";
  }
  return `/collection/${collection.slug}`;
};

export const navigateTo = (dispatch, url = "") => {
  global.app.navigateToPage(dispatch, url);
};

export const generateNavigateSlug = (collection = {}, config = {}, customUrlPath) => {
  if (customUrlPath) {
    return customUrlPath;
  }
  const { magazineSlug = "", isArchivePage = false } = config;
  if (isArchivePage) {
    return `/magazine/archive/${magazineSlug}`;
  }
  if (magazineSlug) {
    return `/magazine/${magazineSlug}/${collection.slug}`;
  }
  if (collection) {
    return getSlug(collection, config);
  }
  return "/";
};

export function replaceWithAlternates(story) {
  const alternates = get(story, ["alternative", "home", "default"]);
  if (!alternates) return story;

  return Object.assign({}, story, {
    headline: alternates.headline || story.headline,
    "hero-image-s3-key": alternates["hero-image"]
      ? alternates["hero-image"]["hero-image-s3-key"]
      : story["hero-image-s3-key"],
    "hero-image-metadata": alternates["hero-image"]
      ? alternates["hero-image"]["hero-image-metadata"]
      : story["hero-image-metadata"],
    "hero-image-caption": alternates["hero-image"]
      ? alternates["hero-image"]["hero-image-caption"]
      : story["hero-image-caption"],
    "hero-image-attribution": alternates["hero-image"]
      ? alternates["hero-image"]["hero-image-attribution"]
      : story["hero-image-attribution"],
  });
}

export const getDate = () => {
  const date = new Date();

  const fullDate = date.toLocaleDateString("en-US", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  });
  return fullDate;
};

export const getLoadMoreCollection = async ({ offset, limit, slug, setStories, stories, setHasMore }) => {
  const response = await getCollectionitems(slug, offset, limit);
  const { items: moreStories, totalCount } = response;
  setStories([...stories, ...moreStories.map((item) => item.story)]);
  setHasMore(offset + limit <= totalCount);
  return null;
};

export const fallbackImage =
  "https://images.assettype.com/gulfnews-uat/2024-10-15/r2sl2lqd/Gulf-News-Fallback-Image.jpg";

