import React from "react";
import { useSelector } from "react-redux";
import get from "lodash/get";
import { Link } from "@quintype/components";
import { SvgIconHandler } from "../../../atoms/svg-icon-hadler";
import "./mobile-nav-menu.m.css";

export default function MobileNavMenus() {
  const qtState = useSelector((state) => get(state, ["qt", "data"], {}));
  const pageType = useSelector((state) => get(state, ["qt", "pageType"], null));
  const dubaiTemperature = get(qtState, ["temperature"], "");

  const mobileNavMenu = get(qtState, ["navigationMenu", "mobileNav"], []);

  const getStyles = () => {
    const navStyle = pageType === "home-page" ? "mobile-nav-menus" : "mobile-nav-menus with-border";
    return navStyle;
  };

  return (
    <div styleName={getStyles()}>
      {mobileNavMenu?.slice(0, 3).map((item) => {
        const iconCode = get(item, ["data", "icon-code"]) || "";
        const width = iconCode === "new-label" ? "33.5px" : "24px";
        const height = iconCode === "new-label" ? "12.4px" : "24px";
        const viewBox = iconCode === "new-label" ? "0 0 34 14" : "0 0 24 24";
        const url = new URL(item?.url);
        const newTarget = url?.hostname === "gulfnews.com" ? "_self" : "_blank";
        return (
          <div styleName="menu-item">
            <Link target={newTarget} rel="noopener noreferrer" href={item.completeUrl || item?.["section-slug"] || "/"}>
              {["dubai", "weather"].includes(item.title.toLowerCase()) ? (
                <span>
                  {dubaiTemperature !== "undefined°C" ? `${item.title} ${dubaiTemperature}` : `${item.title}`}
                </span>
              ) : (
                <>
                  <span>{item.title}</span>
                  {iconCode && (
                    <span>
                      <SvgIconHandler type={iconCode} width={width} height={height} viewBox={viewBox} />
                    </span>
                  )}
                </>
              )}
            </Link>
          </div>
        );
      })}
    </div>
  );
}
