/* eslint-disable react/no-unknown-property */
import React from "react";
import { connect, useSelector } from "react-redux";
import get from "lodash/get";
import { object, string } from "prop-types";
import { Link } from "@quintype/components";
import { MenuItem } from "../menu-item";
import { SvgIconHandler } from "../../atoms/svg-icon-hadler";
import { useDeviceType } from "../../custom-hooks/useDeviceType";
import { DfpComponent } from "../../ads/dfp-component";

import "./footer.m.css";

const handleScrollToTop = () => {
  window.scrollTo({ top: 0, behavior: "smooth" });
};

const StickyAd = () => {
  const pageType = useSelector((state) => get(state, ["qt", "pageType"], null));
  let currentPath = useSelector((state) => get(state, ["qt", "currentPath"], ""))
    .split("?")[0]
    .split("/")[1];
  if (currentPath === "") {
    currentPath = pageType;
  }
  const pageName = useSelector((state) =>
    get(state, ["qt", "config", "ads-config", "ad_name_mapping", currentPath], "sections")
  );
  const networkId = useSelector((state) => get(state, ["qt", "config", "ads-config", "network_id"], ""));
  const adUnit = `/${networkId}/GULFNEWS/Gulfnews_ATF/gulfnews_${pageName}_atf/gulfnews_${pageName}_top_atf`;
  const AdConfig = useSelector((state) => get(state, ["qt", "config", "ads-config", "slots", "sticky_ad"], {}));
  return (
    <DfpComponent
      adStyleName="sticky-ad"
      id={`${pageType}-sticky-ad`}
      path={adUnit}
      size={AdConfig?.sizes}
      viewPortSizeMapping={AdConfig?.view_port_size_mapping}
      isSticky={true}
      isSponsored={true}
      containerStyle="sticky-ad-container"
    />
  );
};

const BackToTop = () => {
  return (
    <div onClick={handleScrollToTop} styleName="back-to-top-btn">
      <div styleName="back-to-top-label">Go back to top</div>
      <SvgIconHandler type="arrow-up" height="24px" width="24px" />
    </div>
  );
};

const FooterBase = ({ menu, storyTemplate }) => {
  const placeholderMenus = menu.filter((item) => (item["item-type"] = "placeholder"));
  const qtState = useSelector((state) => get(state, ["qt"], {}));
  const copyright = get(qtState, ["config", "publisher-settings", "copyright"], "");
  const deviceType = useDeviceType();
  const isMobile = deviceType === "mobile";
  const footerLinks = isMobile
    ? { width: "44px", height: "44px", viewBox: "0 0 44 44" }
    : { width: "48px", height: "48px", viewBox: "0 0 48 48" };
  return (
    <div styleName="footer">
      {isMobile && storyTemplate !== "gn-reach" && <StickyAd />}
      <BackToTop />
      {placeholderMenus[0]?.children.length > 0 && (
        <div styleName="network-links">
          <span styleName="network-links-label">Network links:</span>
          {placeholderMenus[0]?.children.map((item) => {
            return <MenuItem textColor="#dee3e6" fontSize="16px" item={item} key={item.id} />;
          })}
        </div>
      )}
      <div styleName="rows">
        <div styleName="second-row">
          {placeholderMenus[1]?.children.length > 0 && (
            <div styleName="menu-group">
              {placeholderMenus[1]?.children.map((item) => {
                return <MenuItem textColor="#dee3e6" fontSize="14px" item={item} key={item.id} />;
              })}
            </div>
          )}
          {placeholderMenus[2]?.children.length > 0 && (
            <div styleName="social-media">
              <div styleName="social-label">Find us on social</div>
              <div styleName="social-icons">
                {placeholderMenus[2]?.children.map((item) => (
                  <Link key={item.id} href={item.url} target="_blank">
                    <SvgIconHandler
                      type={item.data["icon-code"]}
                      height={footerLinks.height}
                      width={footerLinks.width}
                      viewBox={footerLinks.viewBox}
                    />
                  </Link>
                ))}
              </div>
            </div>
          )}
        </div>
        <div styleName="download-app">
          <div styleName="download-label">Download our app</div>
          <div styleName="app-icons">
            <Link href="https://apps.apple.com/ae/app/gulf-news/id716643133" target="_blank">
              <SvgIconHandler type="app-store" height="48px" width="143.597px" viewBox="0 0 143.597 48" />
            </Link>
            <Link href="https://play.google.com/store/apps/details?id=com.gulfnews.newsapp" target="_blank">
              <SvgIconHandler type="google-play" height="48px" width="161.820px" viewBox="0 0 161.820 48" />
            </Link>
            <Link href="https://appgallery.huawei.com/#/app/C101497957" target="_blank">
              <SvgIconHandler type="app-gallery" height="48px" width="160px" viewBox="0 0 160 48" />
            </Link>
          </div>
        </div>
      </div>
      <div styleName="copyright-info">{copyright}</div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    menu: get(state, ["qt", "data", "navigationMenu", "footer"], []),
    storyTemplate: get(state, ["qt", "data", "story", "story-template"], ""),
  };
}

FooterBase.propTypes = {
  menu: object,
  storyTemplate: string,
};

export const Footer = connect(mapStateToProps, null)(FooterBase);
