export const sendChartbeatUserEvent = (member) => {
  /** CONFIGURATION START **/
  var _sf_async_config = (window._sf_async_config = window._sf_async_config || {});
  var _cbq = (window._cbq = window._cbq || []);
  // User types
  if (member) {
    if (member.isSubscriber) {
      _cbq.push(["_acct", "paid"]);
    } else {
      _cbq.push(["_acct", "lgdin"]);
    }
  } else {
    _cbq.push(["_acct", "anon"]);
  }
};

export const loadChartbeat = () => {
  var e = document.createElement("script");
  var n = document.getElementsByTagName("script")[0];
  e.type = "text/javascript";
  e.async = true;
  e.src = "//static.chartbeat.com/js/chartbeat.js";
  n.parentNode.insertBefore(e, n);
};
