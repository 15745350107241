/* eslint-disable max-len */
export const PAGE_TYPE = Object.freeze({
  HOME_PAGE: "home-page",
  SECTION_PAGE: "section-page",
  COLLECTION_PAGE: "collection-page",
  TAG_PAGE: "tag-page",
  SEARCH_PAGE: "search-page",
  STORY_PAGE: "story-page",
  CATALOG_PAGE: "catalog-page",
  STORY_PUBLIC_PREVIEW_PAGE: "story-public-preview-page",
  STORY_PREVIEW: "story-preview",
  HOME_PREVIEW: "home-preview",
  COLLECTION_PAGE_PREVIEW: "collection-page-preview",
  HOME_PAGE_PREVIEW: "home-page-preview",
  STATIC_PAGE: "static-page",
  FORM_PAGE: "form-page",
  AUTHOR_PAGE: "author-page",
  SUBSCRIPTION_PAGE: "subscription-page",
  RESET_PASSWORD_PAGE: "reset-password-page",
  PROFILE_PAGE: "profile-page",
  USER_LOGIN: "user-login",
  VISUAL_STORY: "visual-story",
  UGC_PAGE: "ugc-page",
  FOREX_PAGE: "forex-page",
  HISTORICAL_FUEL_PAGE: "historical-fuel-page",
  HISTORICAL_GOLD_PAGE: "historical-gold-page",
  HISTORICAL_REMMITANCE_PAGE: "historical-remmitance-page",
  WEATHER_PAGE: "weather-page",
  LATEST_NEWS_PAGE: "latest-news",
  GN_REACH: "gn-reach",
  PRAYER_TIMES: "prayer-times",
  GRATUITY_CALCULATOR_PAGE: "gratuity-calculator-page",
  MAGAZINE_LISTING_PAGE: "magazine-listing-page",
  MAGAZINE_LANDING_PAGE: "magazine-landing-page",
});
export const TAG_PAGE_URL_PREFIX = "/topic/";

export const storyFields =
  "headline,label,subheadline,sections,author-name,authors,hero-image-metadata,hero-image-s3-key,slug,id,last-published-at,read-time,alternative";

export const STORY_FIELDS =
  "id,metadata,story-template,headline,subheadline,sections,slug,hero-image-s3-key,hero-image-metadata,author-name,author-id,authors,url,alternative,last-published-at,first-published-at,hero-image-caption,read-time";

export const NEWSLETTER_API = "https://partners.gulfnews.com/api/v1/newsletters/list";

export const NUM_PARAGRAPHS_BEFORE_PAYWALL = 2;

export const AD_FREQUENCY = 4;

export const FIRST_AD_POSITION = 5;

export const AD_LIMIT = 10;

export const TABOOLA_WIDGET_POSITION = 9;

export const TEMPLATES_WITH_PARAGRAPH_ADS = ["interview", "review", "text", "syndicated", "premium"];

export const TEMPLATES_WITH_STORY_ELEMENT_ADS = ["video", "photo", "story-listicle"];

export const PARTNER_CONTENT_STORY_TEMPLATE = "gn-reach";
