import axios from "axios";
import { STORY_FIELDS } from "../isomorphic/constants";

export const getSearchPageItems = async (slug, offset, limit) => {
  const { results } = await (await fetch(`/api/v1/search?q=${slug}&offset=${offset}&limit=${limit + 1}`)).json();
  const loadMoreStories = results.stories.map((story) => {
    return { type: "story", story: story };
  });
  return loadMoreStories;
};

export const getAdvanceSearchPageItems = async (
  slug,
  offset,
  limit,
  sort = "latest-published",
  sectionId,
  cuisineFilter,
  prepTimeFilterValue,
  cookTimeFilterValue
) => {
  const cuisineFilterVal = cuisineFilter && cuisineFilter !== "All" ? `&story-attributes.cuisine=${cuisineFilter}` : "";
  const prepTimeFilterVal =
    prepTimeFilterValue && prepTimeFilterValue !== "All"
      ? `&story-attributes.preparationtime=${prepTimeFilterValue}`
      : "";
  const cookTimeFilterVal =
    cookTimeFilterValue && cookTimeFilterValue !== "All" ? `&story-attributes.cookingtime=${cookTimeFilterValue}` : "";

  const paramSlug = slug ? `q=${slug}` : "";
  const paramLimitOffset = `&offset=${offset}&limit=${limit}`;
  const paramSort = `&sort=${sort}`;
  const paramAggregations = `&aggregations=section-name,section-id,story-attributes.cuisine,story-attributes.cookingtime,story-attributes.preparationtime`;
  const paramSectionId = sectionId ? `&section-id=${sectionId}` : "";
  const paramFilters = `${cuisineFilterVal}${prepTimeFilterVal}${cookTimeFilterVal}`;

  const queryParamsList =
    window.location.pathname === "/gn-reach"
      ? `${paramSlug}${paramLimitOffset}${paramSort}${paramSectionId}${paramAggregations}&type=gn-reach`
      : `${paramSlug}${paramLimitOffset}${paramSort}${paramSectionId}${paramAggregations}${paramFilters}`;

  const results = await (await fetch(`/api/v1/advanced-search?${queryParamsList}`)).json();
  return results;
};

export const getStories = async (query, slug, offset, limit) => {
  const { stories } = await (
    await fetch(`/api/v1/stories?${query}=${slug}&offset=${offset}&limit=${limit + 1}`)
  ).json();
  const loadMoreStories = stories.map((story) => {
    return { type: "story", story: story };
  });
  return loadMoreStories;
};

export const getCollectionitems = async (slug, offset, limit) => {
  const { items, "total-count": totalCount } = await (
    await fetch(`/api/v1/collections/${slug}?&item-type=story&offset=${offset}&limit=${limit + 1}`)
  ).json();
  return { items, totalCount };
};

export const getMenuGroups = async () => {
  const result = await (await fetch(`/api/v1/menu-groups`)).json();
  return result;
};

export const getAuthorStories = async (id, offset, limit) => {
  const { items } = await (
    await fetch(`/api/v1/authors/${id}/collection?&item-type=story&offset=${offset}&limit=${limit}`)
  ).json();
  return items;
};

export const loadRelatedStories = async (story, config) => {
  const { mountAt } = config || {};
  const mountAtPrefix = mountAt || "";
  const sectionQuery = story.sections && story.sections.length !== 0 ? `section-id=${story.sections[0].id}` : "";
  const response = await global
    .wretch(`${mountAtPrefix}/api/v1/stories/${story.id}/related-stories?limit=4&${sectionQuery}`)
    .query({
      fields: STORY_FIELDS,
    })
    .get()
    .json((relatedStories) => relatedStories["related-stories"]);
  return response;
};

export const getDubaiTemperature = async () => {
  const weatherUrl = "https://dmu-api.gulfnews.com/gn-feeds/data/weather/dubai.json";
  const response = await axios.get(weatherUrl);
  const result = await response.data;
  const temperature = `${result?.data?.main?.temp.toString().slice(0, 2)}\u00B0C`;
  return temperature;
};

export const getTrendingStories = async () => {
  const { name, items } = await (await fetch(`/api/v1/collections/trending-stories?limit=6`)).json();
  return { name, items };
};

export const getLatestStories = async () => {
  const { name, items } = await (await fetch(`/api/v1/collections/latest-stories?limit=6`)).json();
  return { name, items };
};

export const getCoverImage = async (sectionSlug) => {
  const { metadata } = await (await fetch(`/api/v1/collections/${sectionSlug}`)).json();
  return { coverImage: metadata?.["cover-image"] || "" };
};

export const getLiveStoryData = async (storyId) => {
  const { story } = await (await fetch(`/api/v1/stories/${storyId}`)).json();
  return { story };
};

export const shortenUrl = async (longUrl) => {
  const url = "/shorten-url";

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ longUrl }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error shortening URL:", error);
    throw error;
  }
};

export const fetchTokenForShortenUrl = async (host) => {
  const url = "https://api.gn24.ae/v2/shortened-auth";

  try {
    const response = await axios.get(url, {
      headers: {
        Origin: host,
      },
    });
    const { token } = response.data;

    return token;
  } catch (error) {
    console.error("Error fetching token:", error.response ? error.response.data : error.message);
    throw new Error("Failed to fetch token");
  }
};

export const shortenUrlApi = async (longUrl, token) => {
  const url = "https://api.gn24.ae/v2/shortened-url";

  try {
    const response = await axios.post(
      url,
      { longUrl },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error shortening URL:", error.response ? error.response.data : error.message);
    throw new Error("Failed to shorten URL");
  }
};
