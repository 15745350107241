import React from "react";
import PT from "prop-types";
import { Link } from "@quintype/components";
import { useSelector } from "react-redux";
import get from "lodash/get";
import "./menu-item.m.css";
import { SvgIconHandler } from "../../atoms/svg-icon-hadler";

function MenuItem({
  item,
  toggleHandler,
  textColor = "#0c1215",
  menuStyle = "menu-link",
  fontSize = "12px",
  customStyle = {},
}) {
  const iconCode = get(item, ["data", "icon-code"]) || "";
  const width = iconCode === "new-label" ? "33.5px" : "24px";
  const height = iconCode === "new-label" ? "12.4px" : "24px";
  const viewBox = iconCode === "new-label" ? "0 0 34 14" : "0 0 24 24";
  const menuItemUrl = Object.keys(item).length > 0 ? item.url : item.completeUrl;

  const dubaiTemperature = useSelector((state) => get(state, ["qt", "data", "temperature"], ""));
  if (item["item-type"] === "placeholder") {
    return (
      <span styleName={menuStyle} style={{ color: textColor }}>
        {item.title}
      </span>
    );
  } else if (item["item-type"] === "link") {
    const url = new URL(item?.url);
    const newTarget = url?.hostname === "gulfnews.com" ? "_self" : "_blank";
    return (
      <a
        styleName={menuStyle}
        onClick={toggleHandler}
        className={menuStyle}
        target={newTarget}
        rel="noopener noreferrer"
        href={menuItemUrl || item?.["section-slug"] || "/"}
      >
        {item.title.toLowerCase() === ("dubai" || "weather") ? (
          <span style={{ color: textColor, fontSize }}>
            {dubaiTemperature !== "undefined°C" ? `${item.title} ${dubaiTemperature}` : `${item.title}`}
          </span>
        ) : (
          <>
            <span style={{ color: textColor, fontSize }}>{item.title}</span>
            {iconCode && (
              <span style={{ color: textColor }}>
                <SvgIconHandler type={iconCode} width={width} height={height} viewBox={viewBox} />
              </span>
            )}
          </>
        )}
      </a>
    );
  } else {
    return (
      <Link
        styleName={menuStyle}
        aria-label="menu-item"
        callback={toggleHandler}
        className={menuStyle}
        href={menuItemUrl}
        style={customStyle}
      >
        <>
          <span style={{ color: textColor, fontSize }}>{item.title}</span>
          {iconCode && (
            <span style={{ color: textColor, fontSize }}>
              <SvgIconHandler type={iconCode} width={width} height={height} viewBox={viewBox} />
            </span>
          )}
        </>
      </Link>
    );
  }
}

export default MenuItem;

MenuItem.propTypes = {
  item: PT.object,
  showIcon: PT.bool,
  toggleHandler: PT.func,
  menuStyle: PT.string,
  changeTextColor: PT.bool,
  changeTextWeight: PT.bool,
  temperature: PT.string,
  textColor: PT.string,
  fontSize: PT.string,
};

export { MenuItem };
