import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { get } from "lodash";
import { Link } from "@quintype/components";
import MenuItem from "../../layouts/menu-item";
import { NavMoreClose } from "../../atoms/Nav-More-Close";
import { NavMore } from "../../atoms/Nav-More";
import { DfpComponent } from "../../ads/dfp-component";
import "./section-mast.m.css";
import { PAGE_TYPE } from '../../../constants';

const SectionMast = () => {
  const [showMore, setShowMore] = useState(false);
  const sponsorAdConfig = useSelector((state) => get(state, ["qt", "config", "ads-config", "slots", "sponsor_ad"], {}));
  const [deviceType, setDeviceType] = useState("desktop");
  const [width, setWidth] = useState(1200);

  const isMobile = deviceType === "mobile";

  const [showMoreButton, setShowMoreButton] = useState(false);
  const listItemRef = useRef(null);

  const qtState = useSelector((state) => get(state, ["qt"], {}));
  const apiResponse = get(qtState, ["data"], {});
  const pageType = get(qtState, ["pageType"], null);
  const { menuGroups: menuGroupsList, story, coverImage, section, collection, tag } = apiResponse;

  const defaultMenuItems = get(menuGroupsList, ["menuGroups", "home-mast-menu", "items"], []);

  const coverImageUrl = coverImage?.["cover-image-url"];
  const isCoverImageExist = coverImage?.["cover-image-s3-key"];

  const specialPages = [
    { type: "forex-page", name: "Gold Forex", slug: "gold-forex" },
    { type: "prayer-times", name: "Prayer Times", slug: "prayer-times" },
    { type: "weather-page", name: "Weather", slug: "weather" },
    { type: "historical-gold-page", name: "Gold Forex", slug: "gold-forex" },
    { type: "historical-fuel-page", name: "Gold Forex", slug: "gold-forex" },
    { type: "historical-remmitance-page", name: "Gold Forex", slug: "gold-forex" },
    { type: "gratuity-calculator-page", name: "Gratuity Calculator", slug: "gratuity-calculator-uae" },
  ];

  function getSpecialPageSlugByType(type) {
    const page = specialPages.find((page) => page.type === type);
    return page ? page.slug : null; // Return slug if found, else return null
  }

  const specialPageTypes = ["static-page", "gn-reach", "gratuity-calculator-page"]; // Passing defaultMenuItems for these pageTypes

  const isSpecialPage = specialPages.filter((page) => {
    return page.type === pageType;
  });
  const isStaticPage = pageType === "static-page";
  const isTagPage = pageType === "tag-page";
  const isMagazineList = pageType === "magazine-listing-page";
  const isMagazineLanding = pageType === "magazine-landing-page";

  const currentSection =
    (isSpecialPage?.length && isSpecialPage[0]) ||
    (isStaticPage && { name: apiResponse?.title }) ||
    (isMagazineList && { name: apiResponse?.magazine?.name }) ||
    (isMagazineLanding && { name: apiResponse?.data?.name }) ||
    get(story, ["sections", "0"], "") ||
    get(section, ["collection"], null) ||
    collection ||
    tag;
  const slug =
    (isMagazineList && apiResponse?.magazine?.slug) ||
    (isMagazineLanding && apiResponse?.data?.slug) ||
    story?.slug ||
    section?.["section-url"] ||
    getSpecialPageSlugByType(pageType) ||
    pageType;

  let sectionMenuList = [];
  const pathSegments = slug?.split("/").filter(Boolean);

  let currentSlug = pathSegments?.[pathSegments?.length - 1];

  if( pageType == PAGE_TYPE.SECTION_PAGE && pathSegments?.[2] == 'premium' ) { // sub-sections created under PREMUIM
    //Hardcode SectionMenuList to "Premium" to avoid section name collision when a sub-section has the same name as a parent section
    sectionMenuList = menuGroupsList?.menuGroups?.['premium'];
  }
  else {
    for (let i = pathSegments?.length - 1; i >= 0; i--) {
      const currentSegment = pathSegments && pathSegments[i];

      if (menuGroupsList?.menuGroups && currentSegment && menuGroupsList?.menuGroups?.[currentSegment]) {
        sectionMenuList = menuGroupsList?.menuGroups?.[currentSegment];
        break;
      }
    }
  }

  if (!sectionMenuList?.items?.length && currentSection) {
    sectionMenuList = {
      name: currentSection?.name,
      slug: currentSection?.slug,
      items: specialPageTypes.includes(pageType) ? defaultMenuItems : [],
    };
  }

  const toggleMore = () => {
    setShowMore(!showMore);
  };
  const hasCurrentSection = currentSection && Object.keys(currentSection).length;

  const HeaderLogo = ({ requiredClass }) =>
    isCoverImageExist ? (
      <Link href={`/${sectionMenuList?.slug}`}>
        <img src={coverImageUrl} styleName="cover-image" alt="Logo" />
      </Link>
    ) : isStaticPage || isTagPage || isMagazineList || isMagazineLanding ? (
      <Link href="#">
        <div styleName={requiredClass}>{sectionMenuList?.name?.toUpperCase()}</div>
      </Link>
    ) : (
      <Link href={`/${sectionMenuList?.slug}`}>
        <div styleName={requiredClass}>{sectionMenuList?.name?.toUpperCase()}</div>
      </Link>
    );
  const customStyleForDfp = {
    margin: "0px",
  };

  const customStyleForMenuItem = {
    display: "flex",
    height: "22px",
  };
  const selectedCustomStyleForMenuItem = {
    display: "flex",
    height: "22px",
    fontWeight: 700,
    lineHeight: "150%",
  };

  const determineDeviceType = () => {
    setWidth(window.innerWidth);
    if (width < 768) {
      setDeviceType("mobile");
    } else if (width < 1200) {
      setDeviceType("tablet");
    } else {
      setDeviceType("desktop");
    }
  };

  useEffect(() => {
    const handleInitialLoad = () => {
      determineDeviceType();
      if ((sectionMenuList?.items?.length > 0 && isMobile) || listItemRef?.current?.offsetTop > 0) {
        setShowMoreButton(true);
      } else {
        setShowMoreButton(false);
      }
    };

    setTimeout(handleInitialLoad, 500);

    window.addEventListener("resize", determineDeviceType);
    return () => {
      window.removeEventListener("resize", determineDeviceType);
    };
  }, [width, deviceType]);

  return hasCurrentSection ? (
    <div styleName="section-mast-container-main">
      <div styleName="section-mast-header">
        <div styleName="section-mast-container-web">
          <HeaderLogo requiredClass={"logo-text-web"} />
          {/* <DfpComponent
            adStyleName="ad-slot-size-100x60"
            id={`${pageType}-${slug ? `${slug}-` : ""}row-bottom-ad`}
            path={sponsorAdConfig.ad_unit}
            size={sponsorAdConfig.sizes}
            viewPortSizeMapping={sponsorAdConfig.view_port_size_mapping}
            customStyle={customStyleForDfp}
          /> */}
        </div>
        <div
          styleName={`${
            sectionMenuList?.items?.length > 0 || hasCurrentSection || isMobile
              ? "section-mast-nav-container"
              : "section-menu-hidden"
          }`}
        >
          <div styleName="section-mast-container-mobile">
            <HeaderLogo requiredClass={"logo-text-mobile"} />
          </div>
          {sectionMenuList?.items?.length > 0 && (
            <ul styleName={`menu-group ${showMore ? "show-more" : "show-less"}`}>
              {sectionMenuList?.items?.map((menuItem, index) => {
                currentSlug = pageType === "story-page" ? slug : currentSlug;
                const currentStyle =
                  menuItem?.["section-slug"] === currentSlug || currentSlug.includes(menuItem?.["section-slug"]);
                return (
                  <li key={menuItem.title} ref={index === sectionMenuList?.items?.length - 1 ? listItemRef : null}>
                    <MenuItem
                      item={menuItem}
                      menuStyle="menu-link"
                      fontSize="14px"
                      customStyle={
                        menuItem?.["section-slug"] === currentSlug
                          ? selectedCustomStyleForMenuItem
                          : customStyleForMenuItem
                      }
                    />
                  </li>
                );
              })}
            </ul>
          )}
          {showMoreButton && (
            <button styleName="more-btn" onClick={toggleMore}>
              {showMore ? <NavMoreClose /> : <NavMore />}
            </button>
          )}
        </div>
      </div>
      {/*
      <div styleName="mobile-view-sponsor-ad">
        <DfpComponent
            adStyleName="ad-slot-size-100x60"
            id={`${pageType}-${slug ? `${slug}-` : ""}-row-bottom-ad`}
            path="SectionMastAd"
            size={sponsorAdConfig.sizes}
            viewPortSizeMapping={sponsorAdConfig.view_port_size_mapping}
            customStyle={customStyleForDfp}
          />
      </div>
      */}
    </div>
  ) : null;
};

export default SectionMast;
