import React, { Fragment, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import get from "lodash/get";
import PT from "prop-types";
import { Link } from "@quintype/components";
import Button from "../../../atoms/Button";
import { SvgIconHandler } from "../../../atoms/svg-icon-hadler";
import { logoutHandler } from "../../../paywall";
import { MEMBER_UPDATED } from "../../../store/actions";
import SideMenuGroup from "./side-menu-group";
import "./side-menu.m.css";

function SideMenu({ handleToggle, isLoggedInUser, handleUserLogin, isScrolled, topDivHeight }) {
  const state = useSelector((state) => state);
  const qtState = useSelector((state) => get(state, ["qt", "data"], {}));
  const storytemplate = get(qtState, ["story", "story-template"], "text");
  const isHamburgerMenuOpen = get(state, ["isHamburgerMenuOpen"], false);
  const subscription = get(qtState, ["navigationMenu", "subscriptionMenu"], []);
  const tertiaryNav = get(qtState, ["navigationMenu", "tertiaryNav"], []);
  const mainNav = get(qtState, ["navigationMenu", "mainNav"], []);
  const topNav = get(qtState, ["navigationMenu", "topNav"], []);
  const socialMenuGroup = get(qtState, ["navigationMenu", "socialMenuGroup"], []);
  const dispatch = useDispatch();
  const member = useSelector((state) => get(state, ["member"], null));
  const pageType = useSelector((state) => get(state, ["qt", "pageType"], null));
  const SUBSCRIPTIONS_LINK = useSelector((state) =>
    get(state, ["qt", "config", "publisher-attributes", "conscent_configuration", "SUBSCRIPTIONS_LINK"], "")
  );
  const clientId = useSelector((state) =>
    get(state, ["qt", "config", "publisher-attributes", "conscent_configuration", "client_id"], "")
  );

  const getHamburgerStyles = () => {
    let sideMenuStyle = !isScrolled && topDivHeight < 255 ? "dropdown-content" : "dropdown-content-with-top-ad";
    if (storytemplate === "gn-reach") {
      sideMenuStyle = "dropdown-content";
    }
    return sideMenuStyle;
  };

  const sidebarMenus = [
    { menu: subscription, displayStyle: "row", isSubscription: true },
    { menu: tertiaryNav, displayStyle: "row" },
    { menu: mainNav, displayStyle: "column" },
    { menu: topNav, displayStyle: "column" },
  ];

  if (!isHamburgerMenuOpen) {
    return null;
  }

  const handleLogout = () => {
    dispatch({ type: MEMBER_UPDATED, member: null });
    logoutHandler();
  };

  function getRowStyle(item) {
    if (item.displayStyle === "row") {
      return item?.isSubscription ? "menu-row-subscription" : "menu-row-style";
    }
    return "menu-col-style";
  }

  useEffect(() => {
    document.body.style.overflow = "scroll";
    return () => {
      document.body.style.overflow = "scroll";
    };
  }, [isHamburgerMenuOpen]);

  useEffect(() => {
    getHamburgerStyles();
  }, [topDivHeight, isScrolled]);

  return (
    <Fragment>
      <div styleName="overlay" onClick={handleToggle}></div>
      <div
        id="side-menu"
        styleName={isHamburgerMenuOpen ? getHamburgerStyles() : "hide-side-menu"}
      >
        <div styleName="top-div">
          <span>
            <Link href="/" styleName="logo">
              <SvgIconHandler type="logo-small" width="192px" height="28px" viewBox="0 0 192 28" />
            </Link>
          </span>
          <span>
            <Button aria-label="close-button" styleName="close__search" key="2" onClick={handleToggle}>
              <SvgIconHandler type="close" width="32" height="32" viewBox="0 0 20 20" />
            </Button>
          </span>
        </div>
        <div styleName="button-div">
          {isLoggedInUser ? (
            <span>
              <p styleName="welcome-user">
                <span>Welcome</span> {member?.name || "User"}!
              </p>
            </span>
          ) : (
            <>
              <span>
                <Button styleName="login-btn" onClick={handleUserLogin}>
                  LOGIN
                </Button>
              </span>
              <span>
                <Link href={`${SUBSCRIPTIONS_LINK}?clientId=${clientId}`} target="_blank">
                  <button id="subscribe-btn" styleName="subscribe__btn">
                    SUBSCRIBE
                  </button>
                </Link>
              </span>
            </>
          )}
        </div>
        <div styleName="menu-groups">
          {sidebarMenus.length > 0 &&
            sidebarMenus.map((item) => {
              const rowStyle = getRowStyle(item);
              return (
                <ul key={item.id} styleName={rowStyle}>
                  <SideMenuGroup
                    menuItem={item.menu}
                    toggleHandler={handleToggle}
                    textColor={item?.isSubscription ? "#0A5687" : "#0c1215"}
                  />
                </ul>
              );
            })}
          {isLoggedInUser && (
            <ul styleName="logout-div">
              <li onClick={handleLogout}>
                <span>Logout</span>
                <SvgIconHandler type="logout" width="24px" height="24px" viewBox="0 0 24 24" />
              </li>
            </ul>
          )}
        </div>
        {socialMenuGroup.length > 0 && (
          <div styleName="social-group-div">
            <p>Find us on social</p>
            <div styleName="icon-group">
              {socialMenuGroup.map((item) => {
                const iconCode = get(item, ["data", "icon-code"]) || "";
                return (
                  <a key={item.id} target="_blank" rel="noopener noreferrer" href={item.completeUrl || "/"}>
                    <SvgIconHandler type={iconCode} width="36px" height="36px" viewBox="0 0 36 36" />
                  </a>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
}

export default SideMenu;
SideMenu.propTypes = {
  handleToggle: PT.func,
  isLoggedInUser: PT.bool,
  handleUserLogin: PT.func,
};
