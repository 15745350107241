import get from "lodash/get";
import React from "react";
import { useSelector } from "react-redux";
import { useDeviceType } from "../../custom-hooks/useDeviceType";
import { DfpComponent } from "../dfp-component";

const AdsFiller = () => {
  const pageType = useSelector((state) => get(state, ["qt", "pageType"], null));
  let currentPath = useSelector((state) => get(state, ["qt", "currentPath"], ""))
    .split("?")[0]
    .split("/")[1];
  if (currentPath === "") {
    currentPath = pageType;
  }
  const pageName = useSelector((state) =>
    get(state, ["qt", "config", "ads-config", "ad_name_mapping", currentPath], "sections")
  );
  const networkId = useSelector((state) => get(state, ["qt", "config", "ads-config", "network_id"], ""));
  const adUnit = `/${networkId}/GULFNEWS/Gulfnews_ATF/gulfnews_${pageName}_atf/gulfnews_${pageName}_top_atf`;
  const topFillerAdConfig = useSelector((state) =>
    get(state, ["qt", "config", "ads-config", "slots", "top_filler_ad"], {})
  );

  const deviceType = useDeviceType();
  const isDesktop = deviceType === "desktop";

  return (
    <>
      {isDesktop && (
        <DfpComponent
          adStyleName="top-ad-filler"
          id={`${pageType}-top-filler-ad`}
          path={adUnit}
          size={topFillerAdConfig.sizes}
          viewPortSizeMapping={topFillerAdConfig.view_port_size_mapping}
          isSponsored={true}
          centerText={true}
        />
      )}
    </>
  );
};

AdsFiller.propTypes = {};

export { AdsFiller };
