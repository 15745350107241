import get from "lodash/get";

const getSectionId = (state, pageType) => {
  if (pageType === "story-page") {
    return get(state, ["data", "story", "sections", 0, "id"], "NA");
  } else if (pageType === "section-page") {
    return get(state, ["data", "section", "id"], "NA");
  }
  return "NA";
};
const getParentSection = (state, parentId, pageType) => {
  let parentSectionGroup = [];
  if (pageType === "story-page") {
    parentSectionGroup = get(state, ["data", "config", "sections"], []);
  } else if (pageType === "section-page") {
    parentSectionGroup = get(state, ["config", "sections"], []);
  }
  if (parentSectionGroup.length === 0) {
    return null;
  }
  return parentSectionGroup.find((section) => section.id === parentId) || null;
};

const getSectionHierarchy = (state, pageType) => {
  if (pageType === "story-page") {
    const section = get(state, ["data", "story", "sections"], [])[0];
    const parentSection = section["parent-id"] ? getParentSection(state, section["parent-id"], pageType) : null;
    const grandParentSection =
      parentSection && parentSection["parent-id"]
        ? getParentSection(state, parentSection["parent-id"], pageType)
        : null;
    return {
      section: grandParentSection ? grandParentSection.slug : parentSection ? parentSection.slug : section.slug,
      subSection: grandParentSection ? parentSection.slug : parentSection ? section.slug : null,
      subSubSection: grandParentSection && parentSection ? section.slug : null,
    };
  } else if (pageType === "section-page") {
    const section = get(state, ["data", "section"], []);
    const parentSection = section["parent-id"] ? getParentSection(state, section["parent-id"], pageType) : null;
    const grandParentSection =
      parentSection && parentSection["parent-id"]
        ? getParentSection(state, parentSection["parent-id"], pageType)
        : null;
    return {
      section: grandParentSection ? grandParentSection.slug : parentSection ? parentSection.slug : section.slug,
      subSection: grandParentSection ? parentSection.slug : parentSection ? section.slug : null,
      subSubSection: grandParentSection && parentSection ? section.slug : null,
    };
  }

  return [];
};

function getCookieValue(name = "_cscUserId") {
  const cookieString = document.cookie;
  const cookies = cookieString.split("; ");
  for (const cookie of cookies) {
    const [key, value] = cookie.split("=");
    if (key === name) {
      return value === `''` ? null : value;
    }
  }
  return null;
}

const getTagList = (state, pageType) => {
  if (pageType === "story-page") {
    const tags = get(state, ["data", "story", "tags"], []);
    const tagList = tags.map((item) => item.name);
    return tagList;
  }
  return [];
};
const getAuthorList = (state, pageType) => {
  if (pageType === "story-page") {
    const authors = get(state, ["data", "story", "authors"], []);
    const authorsList = authors.map((item) => item.name);
    return authorsList;
  }
  return [];
};
const getStoryTitle = (state, pageType) => {
  if (pageType === "story-page") {
    const storyTitle = get(state, ["data", "story", "headline"], "NA");
    return storyTitle;
  }
  return [];
};

const getArticleId = (state, pageType) => {
  if (pageType === "story-page") {
    return get(state, ["data", "story", "public-identifier"], "NA");
  }
  return "NA";
};
const getArticleLabel = (state, pageType) => {
  if (pageType === "story-page") {
    return get(state, ["data", "story", "metadata", "story-attributes", "label", 0], "NA");
  }
  return "NA";
};
const getArticleType = (state, pageType) => {
  if (pageType === "story-page") {
    return get(state, ["data", "story", "story-template"], "NA");
  }
  return "NA";
};
const getAuthorName = (state, pageType) => {
  if (pageType === "author-page") {
    return get(state, ["data", "author", "name"], "NA");
  }
  return "NA";
};
const getAuthorId = (state, pageType) => {
  if (pageType === "author-page") {
    return get(state, ["data", "author", "id"], "NA");
  }
  return "NA";
};
const getAuthorSlug = (state, pageType) => {
  if (pageType === "author-page") {
    return get(state, ["data", "author", "slug"], "NA");
  }
  return "NA";
};
const getTagName = (state, pageType) => {
  if (pageType === "tag-page") {
    return get(state, ["data", "tag", "name"], "NA");
  }
  return "NA";
};
const getTagSlug = (state, pageType) => {
  if (pageType === "tag-page") {
    return get(state, ["data", "tag", "slug"], "NA");
  }
  return "NA";
};
const getEnvironment = (qtState) => {
  return get(qtState, ["config", "ads-config", "ad_env"], "");
};

export const setViewportSizeMapping = (adSlot, googletag, viewPortSizeMapping) => {
  const sizeMappingBuilder = googletag.sizeMapping();
  if (viewPortSizeMapping) {
    viewPortSizeMapping.forEach((mapping) => {
      sizeMappingBuilder.addSize(mapping.viewport, mapping.sizes);
    });

    const mapping = sizeMappingBuilder.build();
    adSlot.defineSizeMapping(mapping);
  }
};

export const generateLazyLoading = (googletag, fetchMarginPercent, renderMarginPercent, mobileScaling) => {
  const updateSlotStatus = (slotId, state) => {
    const elem = document.getElementById(slotId + "-" + state);
    if (elem) {
      elem.className = "activated";
      elem.innerText = "Yes";
    }
  };

  if (googletag && googletag.pubadsReady) {
    googletag.pubads().addEventListener("slotRequested", function (event) {
      updateSlotStatus(event.slot.getSlotElementId(), "fetched");
    });

    googletag.pubads().addEventListener("slotOnload", function (event) {
      updateSlotStatus(event.slot.getSlotElementId(), "rendered");
    });
  }
};

export const setTargetingParams = (count = 0, googletag, adSlot, qtState) => {
  const pageType = get(qtState, ["pageType"], "");

  const section = getSectionHierarchy(qtState, pageType)?.section;
  const subSection = getSectionHierarchy(qtState, pageType)?.subSection;
  const subSubSection = getSectionHierarchy(qtState, pageType)?.subSubSection;
  const storyType = getArticleType(qtState, pageType);
  const storyAuthorList = getAuthorList(qtState, pageType);
  const storyTagList = getTagList(qtState, pageType);
  const storyTitle = getStoryTitle(qtState, pageType);
  const storyId = getArticleId(qtState, pageType);
  const environment = get(qtState, ["config", "publisher-attributes", "env"], "");
  const sectionId = getSectionId(qtState, pageType);
  const authorName = getAuthorName(qtState, pageType);
  const authorId = getAuthorId(qtState, pageType);
  const authorSlug = getAuthorSlug(qtState, pageType);
  const tagName = getTagName(qtState, pageType);
  const tagSlug = getTagSlug(qtState, pageType);
  if (pageType === "author-page") {
    adSlot.setTargeting("author_name", authorName);
    adSlot.setTargeting("author_id", authorId);
    adSlot.setTargeting("author_slug", authorSlug);
  }
  if (pageType === "tag-page") {
    adSlot.setTargeting("tag_name", tagName);
    adSlot.setTargeting("tag_slug", tagSlug);
  }
  adSlot.addService(googletag.pubads());
  pageType && adSlot.setTargeting("page_type", pageType.split("-")[0]);
  section && adSlot.setTargeting("page_section", section);
  subSection && adSlot.setTargeting("page_subsection", subSection);
  subSubSection && adSlot.setTargeting("page_subsubsection", subSubSection);
  storyType && adSlot.setTargeting("story_type", storyType);
  storyId && adSlot.setTargeting("story_id", storyId);
  getEnvironment(qtState) && adSlot.setTargeting("environment", getEnvironment(qtState));
  getCookieValue() && adSlot.setTargeting("UUID", getCookieValue());
  adSlot.setTargeting("Logged In", getCookieValue() ? true : "false");
};

export const useDfpSlot = ({
  path,
  size,
  id,
  qtState,
  viewPortSizeMapping,
  storySectionSlug,
  refreshAdUnit,
  pageSection,
  pageSubSection,
  pageSubSubSection,
  articleId,
  articleType,
  articleLabel,
  cxSegments,
  loggedIn,
  lastLoggedInDate,
  subscribed,
  type,
  adsConfig,
  count,
}) => {
  const adUnitsToSkip = ["_OOP_", "ear_atf", "editorial_home", "textad", "native", "sponsor"];
  const makeBid = adsConfig?.enable_prebid_ads && !adUnitsToSkip.some((e) => path?.toLowerCase().includes(e));

  window.googletag.cmd.push(function () {
    const responsiveAdSlot = window.googletag.defineSlot(path, size, id);
    const adsConfig = get(qtState, ["config", "ads-config", "dfp_ads"], {});
    const enableLazyLoadAds = get(adsConfig, ["enable_lazy_load_ads"]);
    const fetchMarginPercent = get(adsConfig, ["fetch_margin_percent"], 0);
    const renderMarginPercent = get(adsConfig, ["render_margin_percent"], 0);
    const mobileScaling = get(adsConfig, ["mobile_scaling"], 0);
    if (responsiveAdSlot) {
      id !== "home-page-top-filler-ad" && responsiveAdSlot.setCollapseEmptyDiv(true);
      setTargetingParams(
        count,
        window.googletag,
        responsiveAdSlot,
        qtState,
        storySectionSlug,
        pageSection,
        pageSubSection,
        pageSubSubSection,
        articleId,
        articleType,
        articleLabel,
        cxSegments,
        loggedIn,
        lastLoggedInDate,
        subscribed
      );
      setViewportSizeMapping(responsiveAdSlot, window.googletag, viewPortSizeMapping);
      if (enableLazyLoadAds) {
        generateLazyLoading(window.googletag, fetchMarginPercent, renderMarginPercent, mobileScaling);
      }
      responsiveAdSlot.setCollapseEmptyDiv(true);
    }
  });

  googletag.cmd.push(function () {
    googletag.display(id);

    if (makeBid) {
      executeParallelAuctionAlongsidePrebid({
        path,
        size,
        id,
      });
    } else {
      refreshExistingAdUnit(id);
    }
  });
};

export const getAdSlots = ({
  path,
  size,
  id,
  qtState,
  type,
  viewPortSizeMapping,
  storySectionSlug,
  loadAdsSynchronously,
  delayPeriod,
  refreshAdUnit,
  pageSection,
  pageSubSection,
  pageSubSubSection,
  articleId,
  articleType,
  articleLabel,
  cxSegments,
  loggedIn,
  lastLoggedInDate,
  subscribed,
  adsConfig,
  count,
}) => {
  if (loadAdsSynchronously) {
    useDfpSlot({
      path: path,
      size: size,
      id: id,
      qtState: qtState,
      type: type,
      viewPortSizeMapping: viewPortSizeMapping,
      storySectionSlug,
      refreshAdUnit,
      pageSection: pageSection,
      pageSubSection: pageSubSection,
      pageSubSubSection: pageSubSubSection,
      articleId: articleId,
      articleType: articleType,
      articleLabel: articleLabel,
      cxSegments: cxSegments,
      loggedIn: loggedIn,
      lastLoggedInDate: lastLoggedInDate,
      subscribed: subscribed,
      adsConfig,
      count,
    });
  } else {
    setTimeout(() => {
      useDfpSlot({
        path: path,
        size: size,
        id: id,
        qtState: qtState,
        type: type,
        viewPortSizeMapping: viewPortSizeMapping,
        storySectionSlug,
        refreshAdUnit,
        pageSection: pageSection,
        pageSubSection: pageSubSection,
        pageSubSubSection: pageSubSubSection,
        articleId: articleId,
        articleType: articleType,
        articleLabel: articleLabel,
        cxSegments: cxSegments,
        loggedIn: loggedIn,
        lastLoggedInDate: lastLoggedInDate,
        subscribed: subscribed,
        adsConfig,
        count,
      });
    }, delayPeriod);
  }
};

function refreshExistingAdUnit(id) {
  window.googletag.cmd.push(function () {
    // detect whether PubAdsService is fully loaded
    const existingSlot = window.googletag
      .pubads()
      .getSlots()
      .filter((item) => id === item.getSlotElementId());

    window.googletag.pubads().refresh(existingSlot);
  });
}

export function executeParallelAuctionAlongsidePrebid({ path, size, id }) {
  var FAILSAFE_TIMEOUT = 3000;
  var requestManager = {
    adserverRequestSent: false,
    aps: false,
    prebid: false,
  };
  window.pbjs = window.pbjs || {};
  window.pbjs.que = window.pbjs.que || [];

  let adUnits = {
    code: path,
    mediaTypes: {
      banner: {
        sizes: size,
      },
    },
    bids: [
      {
        bidder: "criteo",
        params: {
          placementId: 9595,
        },
      },
      {
        bidder: "appnexus",
        params: {
          placementId: 20595271,
        },
      },
    ],
  };
  if (Array.isArray(size) && !!size.find((item) => item[0] == 300 && item[1] == 250)) {
    adUnits = {
      code: path,
      mediaTypes: {
        banner: {
          sizes: size,
        },
      },
      bids: [
        {
          bidder: "teads",
          params: {
            placementId: 135843,
            pageId: 124799,
          },
        },
      ],
    };
  }
  const PREBID_CUSTOM_CONFIG = {
    buckets: [
      {
        precision: 2,
        min: 0,
        max: 20,
        increment: 0.01,
      },
      {
        precision: 2,
        min: 20,
        max: 40,
        increment: 0.05,
      },
    ],
  };

  // When both APS and Prebid have returned, initiate ad request
  function biddersBack() {
    if (requestManager.aps && requestManager.prebid) {
      sendAdserverRequest();
    }
    return;
  }

  // Sends adserver request
  function sendAdserverRequest() {
    if (requestManager.adserverRequestSent === true) {
      return;
    }
    requestManager.adserverRequestSent = true;
    refreshExistingAdUnit(id);
  }

  // Sends bid request to APS and Prebid
  function requestHeaderBids() {
    // APS request
    apstag.fetchBids(
      {
        slots: [
          {
            slotID: id,
            slotName: path,
            sizes: size,
          },
        ],
      },
      function (bids) {
        googletag.cmd.push(function () {
          apstag.setDisplayBids(); // Display bids after APS has fetched them
          requestManager.aps = true; // Marks APS request as complete
          biddersBack(); // Checks whether both APS and Prebid have returned
        });
      }
    );

    // Prebid request
    pbjs.que.push(function () {
      pbjs.addAdUnits(adUnits);
      pbjs.setConfig({ priceGranularity: PREBID_CUSTOM_CONFIG });
      pbjs.requestBids({
        bidsBackHandler: function () {
          googletag.cmd.push(function () {
            pbjs.setTargetingForGPTAsync(); // Apply Prebid targeting to GPT
            requestManager.prebid = true; // Marks Prebid request as complete
            biddersBack(); // Checks whether both APS and Prebid have returned
          });
        },
      });
    });
  }

  // Initiate bid request
  requestHeaderBids();

  // Set failsafe timeout to send ad server request if bids aren't returned in time
  window.setTimeout(function () {
    sendAdserverRequest();
  }, FAILSAFE_TIMEOUT);
}
