import get from "lodash/get";
import { array, string } from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

import { SvgIconHandler } from "../../atoms/svg-icon-hadler";
import { getAdSlots } from "../utils";

import "./dfp-component.m.css";

const DfpComponent = ({
  adStyleName,
  containerStyle = "",
  id,
  size,
  path,
  type = "",
  viewPortSizeMapping,
  customStyle = {},
  isSponsored = false,
  isStickyAd = false,
  isSticky = false,
  count = 0,
  centerText = false,
  bgColor = true,
}) => {
  const qtState = useSelector((state) => get(state, ["qt"], {}));
  const adsConfig = get(qtState, ["config", "ads-config", "dfp_ads"], {});
  const loadAdsSynchronously = get(adsConfig, ["load_ads_synchronously"], null);
  const enableAds = get(adsConfig, ["enable_ads"], null);
  const currentPath = get(qtState, ["currentPath"], null);
  const delayAdLoad = get(adsConfig, ["delay_ad_load"], 4);
  const hideAdText = isSponsored || adStyleName === "ad-slot-size-100x60";
  const [hideAd, setHideAd] = useState(false);
  const adRef = useRef(null);

  if (!enableAds) {
    return null;
  }
  const stickyPageAd = isStickyAd
    ? `ad-slot ${adStyleName} sticky-ad-bottom`
    : `ad-slot ${adStyleName} ${bgColor ? "" : "ad-slot-no-bg"}`;
  const containerStyleName = containerStyle ? `ad-slot-container ${containerStyle}` : "ad-slot-container";
  const adContainerStyle = adStyleName !== "top-ad-filler" ? "ad-container" : "";
  const rowBottomAdUnit = id.includes("row-bottom-ad") ? `${containerStyleName} bottom-ad-style` : containerStyleName;
  const sixColSevenAdUnit = id.includes("row-bottom-ad") ? `${containerStyleName} bottom-ad-style` : containerStyleName;

  const minHeightMapping = { mobile_min_height: "", tablet_min_height: "", desktop_min_height: "" };

  const viewPortConditions = {
    "1024,0": "desktop-min-height",
    "768,0": "tablet-min-height",
    "0,0": "mobile-min-height",
  };

  viewPortSizeMapping?.forEach((ele) => {
    const key = `${ele.viewport[0]},${ele.viewport[1]}`;
    const heightKey = viewPortConditions[key];

    if (heightKey) {
      const itemSizes = ele.sizes.filter((e) => e[1] > 1);
      const minSecond = Math.min(...itemSizes.map((item) => item[1]));
      minHeightMapping[heightKey] = minSecond;
    }
  });

  const customStyleProps = {
    "--mobile-min-height": minHeightMapping["mobile-min-height"] && `${minHeightMapping["mobile-min-height"]}px`,
    "--tablet-min-height": minHeightMapping["tablet-min-height"] && `${minHeightMapping["tablet-min-height"]}px`,
    "--desktop-min-height": id?.includes("top-filler-ad")
      ? "282px"
      : minHeightMapping["desktop-min-height"] && `${minHeightMapping["desktop-min-height"]}px`,
    ...customStyle,
  };

  const handleCloseClick = () => {
    setHideAd(true);
  };

  useEffect(() => {
    getAdSlots({
      path,
      size,
      id,
      qtState,
      type,
      viewPortSizeMapping,
      loadAdsSynchronously,
      delayPeriod: delayAdLoad * 1000,
      count,
      refreshAdUnit: false,
      adsConfig,
    });
  }, [currentPath]);

  return (
    <div styleName={`${rowBottomAdUnit}`} style={hideAd ? { display: "none" } : {}} ref={adRef}>
      {isSticky && (
        <div onClick={handleCloseClick} styleName="ad-close">
          <SvgIconHandler type={"close"} width="22" height="22" iconStyle={{ color: "#596E79" }} />
        </div>
      )}
      <div
        styleName={hideAdText ? `${stickyPageAd}` : `${stickyPageAd} ad-text`}
        id={id}
        style={{ ...customStyleProps }}
      ></div>
    </div>
  );
};

DfpComponent.propTypes = {
  adStyleName: string,
  id: string,
  size: array,
  path: string,
  type: string,
  viewPortSizeMapping: array,
};

export { DfpComponent };
